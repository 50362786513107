import Repository from "../Repository";
import { apiConfig } from "../../_helpers/apiHelper";

const resource = "/file";

export default {
  get(file) {
    const config = {
      headers: apiConfig()?.headers,
      responseType: "blob",
    };

    return Repository.get(`${resource}/${file}`, config);
  },

  getReportHistory(path) {
    const config = {
      headers: apiConfig()?.headers,
      responseType: "blob",
    };

    return Repository.get(`${resource}/report-history/${path}`, config);
  },

  getReportTemplate(path) {
    const config = {
      headers: apiConfig()?.headers,
      responseType: "blob",
    };

    return Repository.get(`${resource}/report-template/${path}`, config);
  },
};
