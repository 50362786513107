<template>
  <v-container fluid class="mt-4">
    <v-row class="ml-2 mb-2">
      <v-btn color="primary" outlined text to="/report/template/create">{{
        $t("report.template.create")
      }}</v-btn>
    </v-row>

    <v-flex>
      <v-data-table :headers="headers" :items="templates">
        <template v-slot:[`item.exportType`]="{ item }">
          {{ $t(`report.exportTypes.${item.exportType}`) }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ humanDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ humanDate(item.updatedAt) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            color="success"
            fab
            x-small
            class="mr-2"
            @click="downloadFile(item.path)"
          >
            <v-icon>mdi-download-box</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            fab
            x-small
            class="mr-2"
            :to="'/report/template/' + item.reportSoTemplateId"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn color="error" fab x-small @click="deleteTemplateOpen(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-flex>

    <delete-dialog
      :dialog="deleteDialog"
      @confirm-delete="deleteTemplate"
      @cancel-dialog="deleteDialog = false"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { saveAs } from "file-saver";
import FileRepository from "@/api/repositories/fileRepository.js";
import DeleteDialog from "@/components/common/DeleteDialog";

export default {
  name: "ReportTemplateList",

  components: {
    DeleteDialog,
  },

  data() {
    return {
      template: null,
      deleteDialog: false,
      headers: [
        { text: this.$t("report.template.fields.name"), value: "name" },
        {
          text: this.$t("report.template.fields.exportType"),
          value: "exportType",
        },
        {
          text: this.$t("report.template.fields.description"),
          value: "description",
        },
        { text: this.$t("report.fields.createdAt"), value: "createdAt" },
        { text: this.$t("report.fields.updatedAt"), value: "updatedAt" },
        { text: "", value: "action", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState("reports", ["templates"]),
  },

  methods: {
    ...mapActions("reports", {
      getTemplates: "getTemplates",
      storeDeleteTemplate: "deleteTemplate",
    }),
    ...mapActions("alert", ["error"]),

    deleteTemplateOpen(template) {
      this.template = template;
      this.deleteDialog = true;
    },

    async deleteTemplate() {
      let template = this.template;
      if (template?.reportSoTemplateId === undefined) return;

      this.deleteDialog = false;
      await this.storeDeleteTemplate(template.reportSoTemplateId);
      await this.getTemplates();
    },

    async downloadFile(path) {
      try {
        const response = await FileRepository.getReportTemplate(path);
        const blob = new Blob([response.data]);
        saveAs(blob, path);
      } catch (error) {
        console.error("Error downloading file:", error);
        this.error(this.$t("common.errorDownload"));
      }
    },
  },

  async created() {
    await this.getTemplates();
  },
};
</script>
