<template>
  <v-tabs v-model="tabKey">
    <v-tab :key="0">{{ $t("report.title") }}</v-tab>
    <v-tab :key="1">{{ $t("report.history.title") }}</v-tab>
    <v-tab :key="2" v-if="permitted('Report.Update')">{{
      $t("report.template.title")
    }}</v-tab>

    <v-tab-item>
      <div>
        <v-data-table
          :headers="headers"
          :items="reports"
          :loading="status.loading"
          :items-per-page="50"
          class="elevation-1"
        >
          <template v-slot:[`item.tempo`]="{ item }">
            <v-chip outlined>{{ getTempo(item.tempo) }}</v-chip>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ humanDate(item.createdAt, "dd MMM yyyy - HH:mm") }}
          </template>
          <template v-slot:[`item.lastSent`]="{ item }">
            {{ humanDate(item.lastSent, "dd MMM yyyy - HH:mm") }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              color="accent"
              fab
              x-small
              class="mr-2"
              @click.prevent="exportOpenDialog(item)"
            >
              <v-icon>mdi-download-box</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              fab
              x-small
              class="mr-2"
              @click.prevent="clickedRow(item)"
              v-if="permitted('Report.Update')"
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-tab-item>
    <v-tab-item>
      <report-history-list
        :fetch-trigger="fetchTrigger"
        :resource-type="resourceTypes.COMPANY"
      ></report-history-list>
    </v-tab-item>
    <v-tab-item v-if="permitted('Report.Update')">
      <report-template-list />
    </v-tab-item>

    <v-dialog v-model="dialog" max-width="550">
      <v-card>
        <v-card-title>{{ $t("report.export") }}</v-card-title>
        <v-card-text>
          {{ $t("report.exportDescription") }}
          <v-spacer></v-spacer>
          <v-date-picker v-model="rangeArr" range />
          <v-spacer></v-spacer>
          <v-btn
            :disabled="rangeArr.length < 2"
            color="primary"
            :loading="loading"
            @click="exportAndDownload"
          >
            {{ $t("common.run") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-tabs>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ResourceTypes } from "../../_helpers/CsharpEnum.js";
import ReportRepository from "../../api/repositories/reportRepository.js";

import ReportHistoryList from "./ReportHistoryList.vue";
import ReportTemplateList from "./template/ReportTemplateList.vue";

export default {
  name: "ReportList",

  components: {
    ReportHistoryList,
    ReportTemplateList,
  },

  data() {
    return {
      tabKey: 0,
      fetchTrigger: 0,
      loading: false,
      selectedReport: null,
      dialog: false,
      range: { start: null, end: null },
      rangeArr: [],
      headers: [
        { text: this.$t("report.fields.name"), value: "name" },
        { text: this.$t("report.fields.createdAt"), value: "createdAt" },
        { text: this.$t("report.fields.tempo"), value: "tempo" },
        { text: this.$t("report.fields.lastSent"), value: "lastSent" },
        { text: this.$t("report.fields.manualGeneration"), value: "action" },
      ],
    };
  },
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("reports", { status: "status", reports: "reports" }),
    resourceTypes() {
      return ResourceTypes;
    },
  },
  methods: {
    ...mapActions("reports", ["getReports", "setCurrentReport"]),
    ...mapActions("alert", ["error"]),

    exportOpenDialog(report) {
      this.selectedReport = report;
      this.dialog = true;
    },

    async exportAndDownload() {
      this.loading = true;
      if (this.rangeArr.length < 2) {
        this.loading = false;
        this.error(this.$t("report.errorRange"));
        return;
      }

      // Export and download endpoint
      var result = this.rangeArr
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
        .reverse();
      var start = result[0];
      var end = result[1] ?? new Date();

      try {
        await ReportRepository.manualExport(
          this.selectedReport.reportId,
          start,
          end
        );

        // Send them to the history page instead of instant download
        this.tabKey = 1;
        this.fetchTrigger++;
      } catch (error) {
        console.error("Error downloading file:", error);
        this.error(this.$t("common.errorDownload"));
      }
      this.loading = false;
      this.dialog = false;
    },

    async clickedRow(item) {
      await this.setCurrentReport(item);

      if (this.permitted("Report.Update"))
        this.$router.push(`/report/${item.reportId}`);
    },

    getTempo(tempo) {
      return this.$t(`report.tempo.${tempo}`);
    },
  },

  created() {
    this.getReports();
  },
};
</script>

<style></style>
