<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" :md="12" :sm="12">
        <v-data-table
          :items="histories"
          :headers="headers"
          :items-per-page="25"
          @click:row="showStacktrace"
        >
          <template v-slot:[`item.success`]="{ item }">
            <v-icon :color="item.success ? 'success' : 'error'" class="ml-2">
              {{ item.success ? "mdi-check-circle" : "mdi-alpha-x-circle" }}
            </v-icon>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ humanDate(item.createdAt, "dd MMM yyyy - HH:mm") }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              color="success"
              fab
              x-small
              class="mr-2"
              @click.stop="downloadFile(item.fileName)"
            >
              <v-icon>mdi-download-box</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="stacktraceDialog">
      <v-card>
        <v-card-title> Stacktrace </v-card-title>
        <v-card-text>
          {{ stacktrace }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="closeStacktraceDialog"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import ReportRepository from "../../api/repositories/reportRepository.js";
import FileRepository from "../../api/repositories/fileRepository.js";
import { ResourceTypes, ResourceTypeNames } from "../../_helpers/CsharpEnum.js";
import { mapActions } from "vuex";
import { saveAs } from "file-saver";

export default {
  name: "ReportHistoryList",
  props: {
    fetchTrigger: {
      default: 0,
      required: false,
    },
    resourceType: {
      default: 0,
      required: true,
    },
  },

  data() {
    return {
      stacktraceDialog: false,
      stacktrace: "",
      histories: [],
      headers: [
        { text: this.$t("report.history.success"), value: "success" },
        { text: this.$t("report.history.message"), value: "message.message" },
        { text: this.$t("common.createdAt"), value: "createdAt" },
        { text: "", value: "action" },
      ],
    };
  },

  computed: {
    supportedResourceTypes() {
      let supported = [
        ResourceTypes.COMPANY,
        ResourceTypes.USER,
        ResourceTypes.REPORT,
      ];

      return supported;
    },
  },

  methods: {
    ...mapActions("alert", ["error"]),

    resourceTypeName(resourceType) {
      return ResourceTypeNames[resourceType];
    },

    async fetch() {
      this.histories = await ReportRepository.getHistories(this.resourceType);
    },

    async downloadFile(path) {
      try {
        const response = await FileRepository.getReportHistory(path);
        const blob = new Blob([response.data]);
        saveAs(blob, path);
      } catch (error) {
        console.error("Error downloading file:", error);
        this.error(this.$t("common.errorDownload"));
      }
    },

    showStacktrace(stacktrace) {
      if (!this.permitted("SuperAdmin")) return;
      this.stacktraceDialog = true;
      this.stacktrace = stacktrace;
    },
    closeStacktraceDialog() {
      this.stacktraceDialog = false;
    },
  },

  async created() {
    await this.fetch();
  },

  watch: {
    async resourceType() {
      await this.fetch();
    },

    async fetchTrigger(val) {
      if (val == 0) return;

      await this.fetch();
    },
  },
};
</script>
